<template>
  <v-app id="inspire">
    <div class="px-4">
      <loading
        :is-show="loadingDialog"
        :is-done="loadingFinished"
        :is-html="isHtml"
        :message="loadingMessage"
        v-on:close-dialog="closeDialog"
      />
      <details-form
        :is-show="isShowDetails"
        :details="details"
        v-on:close-dialog="closeDialog"
      />
      <v-container class="container--fluid grid-list-md text-center">
        <h1>All Transactions</h1>
      </v-container>

      <v-data-table
        :hide-default-header="true"
        :headers="headers"
        :items="displayTransactions"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 200],
        }"
        :items-per-page="20"
        :server-items-length="totalTransactions"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr :colspan="headers.length">
              <th v-for="header in headers" :key="header.text">
                <span class="d-flex justify-center">{{ header.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:top>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            outlined
            label="Search"
            hint="*Search current page only"
            persistent-hint
            class="mx-4 mb-10"
          ></v-text-field>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr :colspan="headers.length" v-for="item in items" :key="item.id">
              <td>
                <span class="d-flex justify-center"> {{ item.id }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.transactionType }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.amount }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.dateCreated }} </span>
              </td>
              <td>
                <span class="d-flex justify-center">
                  {{ item.source ? item.source : "n/a" }}
                </span>
              </td>
              <td>
                <span class="d-flex justify-center">
                  {{ item.destination ? item.destination : "n/a" }}
                </span>
              </td>
              <td>
                <span class="d-flex justify-center">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="green"
                    class="mr-2"
                    @click.stop="viewDetails(item)"
                  >
                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Found no results.
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>

<script>
import API from "@/api/API.js";
import Loading from "@/views/components/Loading.vue";
import DetailsForm from "@/views/components/DetailsForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "Transactions",
  components: {
    Loading,
    DetailsForm,
  },
  data() {
    return {
      search: "",
      headers: [],
      displayTransactions: [],
      currentTransactions: [],
      loading: true,
      loadingMessage: "",
      loadingDialog: false,
      loadingFinished: false,
      selectedTransaction: {},
      page: 1,
      totalTransactions: 0,
      numberOfPages: 0,
      options: {},
      details: {
        title: "",
        item: {},
      },
      query: {},
      isShowDetails: false,
      showFormDialog: false,
      isHtml: false,
    };
  },
  watch: {
    options: {
      handler() {
        console.log("options");
        this.queryData();
      },
      deep: true,
    },
    params: {
      handler: function handler() {
        this.filter();
      },
      deep: true,
    },
  },
  computed: {
    //get role attributes
    ...mapGetters(["attributes", "user", "authorities"]),
    params(nv) {
      return {
        ...this.options,
        query: this.search,
      };
    },
  },
  async mounted() {
    this.setHeaders();
    //this.queryData()
  },
  methods: {
    async queryData(isSearch = false) {
      this.loading = true;
      if (isSearch) {
        this.options.page = 1;
      }
      const { page, itemsPerPage } = this.options;
      let pageNumber = page - 1;
      try {
        const limit = itemsPerPage;
        const transactionListResponse = await API.getAllTransactions(
          pageNumber,
          limit,
          this.query
        );

        if (!transactionListResponse || transactionListResponse.error) {
          //error getting data
          console.log(`${transactionListResponse.error}`);
        } else {
          this.displayTransactions = transactionListResponse.transactions;
          this.totalTransactions = transactionListResponse.totalItems;
          this.numberOfPages = this.totalTransactions / itemsPerPage;
          const hasMoreResponse = await API.getAllTransactions(
            this.totalTransactions + 1,
            1,
            this.query
          );
          if (!hasMoreResponse || hasMoreResponse.error) {
            //error getting data
            console.log(`${hasMoreResponse.error}`);
          } else {
            var hasMoreList = hasMoreResponse;
            if (hasMoreList.length > 0) {
              this.totalTransactions = this.totalTransactions + 1;
              this.numberOfPages = this.numberOfPages + 1;
            }
          }
        }
        this.currentTransactions = this.displayTransactions;
        this.filter();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    filter() {
      let search = this.search.trim().toLowerCase();
      if (search) {
        this.displayTransactions = this.currentTransactions.filter((item) => {
          return Object.values(item).join(",").toLowerCase().includes(search);
        });
      } else {
        this.displayTransactions = this.currentTransactions;
      }
    },
    setHeaders() {
      var headers = [
        {
          text: "Transaction ID",
          value: "id",
        },
        {
          text: "Type",
          value: "transactionType",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Date Created",
          value: "dateCreated",
        },
        {
          text: "Source",
          value: "source",
        },
        {
          text: "Destination",
          value: "destination",
        },
        {
          text: "Actions",
          value: "controls",
          sortable: false,
        },
      ];
      this.headers = headers;
    },
    closeDialog(type) {
      if (type === "details") {
        this.details.title = "";
        this.details.item = {};
        this.isShowDetails = false;
      } else if (type === "loading") {
        this.loadingDialog = false;
        this.$router.go(0);
      }
    },
    viewDetails(details) {
      this.details.title = details.externalId;
      this.details.item = Object.assign({}, details);
      this.isShowDetails = true;
    },
  },
};
</script>

<style scoped>
.v-data-table {
  max-width: 100%;
  margin-bottom: 50px;
}
</style>
